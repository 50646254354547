import React from "react";
import './FooterSection.css';
import { Image, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import IICAcademy from '../../assets/images/IICAcademy-2.png';
import sextant from '../../assets/images/sextant-1.png'
const FooterSection = () => {
    return(
        <>
        <Container fluid>
            <footer className="row bg_footer" id="contactUs">
                <div className="col-12 mt-4">
                    <div className="px-4 py-1 padding_0">
                        <Nav.Link className="p-2" as={Link} to="/">
                        <Image role="presentation" alt="" src={IICAcademy}/>
                        </Nav.Link>
                    </div>
                </div>
                <div className="col-12 col-sm-4 mt-2">
                    <div className="intro p-4 py-1">
                        <Image className="px-4 mb-0" src={sextant} />
                    </div>
                </div>
                <div className="col-12 col-sm-8 my-2">
                    <p className="intro_para pb-4"><h2 className="pb-2">Do you have questions? <br/> Email Us: </h2></p>
                        <div className="row mb-5">
                            <div className="col-12 col-md-6 mb-4">
                                <p className="m-0 intro_para font-weight-bold">General information of S-5B</p>
                                <p className="m-0 intro_para font-weight-normal">E-mail: <a href="mailto:hydrographicsurveyor@iicacademy.com" className="text-white homenav font-weight-light">hydrographicsurveyor@iicacademy.com </a></p>
                            </div>
                                <div className="col-12 col-md-6 mb-4">
                                    <p className="m-0 intro_para font-weight-bold">General information of S-8B</p>
                                    <p className="m-0 intro_para font-weight-normal">E-mail: <a href="mailto:nauticalcartographer@iicacademy.com" className="text-white homenav font-weight-light">nauticalcartographer@iicacademy.com </a></p>
                                </div>
                        </div>
                </div>
                <section className="col-12 mx-sm-3 mx-0">
                    <div className="footer_copyright py-2 py-sm-0 px-sm-4">
                        <p className="m-0 p-0 white_text homenav">© Copyright by <a href="https://www.iictechnologies.com/" target="_blank" rel="noreferrer" className="white_text homenav footerbtn">IIC TECHNOLOGIES.</a><br/> All Rights Reserved.</p>
                    </div>
                </section>
            </footer>
            </Container>
        </>
    )
}
export default FooterSection;